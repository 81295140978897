import { IWixAPI } from '@wix/native-components-infra/dist/src/types/types';
import { RequiredKeys } from '@wix/blocks-widget-services-types';

export function assertModuleLoader(
  wixCodeApi: IWixAPI,
): asserts wixCodeApi is RequiredKeys<IWixAPI, 'environment'> {
  if (!wixCodeApi.environment?.network.importAMDModule) {
    throw new Error('importAMDModule is not defined');
  }
}
